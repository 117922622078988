import {createFunctionWithTimeout} from "./utils";

function handleTrackedSubscribe(event) {
  const form = event.currentTarget;
  var formSubmitted = false;

  function handleSubscribe() {
    if (!formSubmitted) {
      formSubmitted = true;
      const req = new XMLHttpRequest();
      req.open(form.method, form.action);
      req.addEventListener('readystatechange', () => {
        if (req.status >= 200 && req.status < 300) success();
        else failure();
      });
      req.send(new FormData(form));
    }
  }

  event.preventDefault();

  function success () {
    form.classList.add('is-hidden');
    document.querySelector('.js-subscribe').querySelector('.js-success').classList.remove('is-hidden');
  }

  function failure () {
    document.querySelector('.js-subscribe').querySelector('.js-failure').classList.remove('is-hidden');
  }

  handleSubscribe();
}

export default function subscribe () {
  const subscribe = document.querySelector('.js-subscribe');

  if (subscribe) {
    subscribe.querySelector('form').addEventListener('submit', handleTrackedSubscribe, false);
  }
}
