import SimplexNoise from 'simplex-noise'
import { Point, drawCanvas, getNewDrawPoints } from './helpers';

function run (container, initialPoints, speed, offset, blocks, defaultOrigin, fillColor) {
  window.addEventListener("load", _init, false);

  const simplex = new SimplexNoise();
  const canvas = container.querySelector("canvas");
  const ctx = canvas.getContext("2d");
  const ratio = window.devicePixelRatio;

  fillColor = canvas.hasAttribute('data-color') ? canvas.getAttribute('data-color') : fillColor;

  let drawPoints = [];
  let index = 0;
  let containerWidth = container.clientWidth;
  let containerHeight = container.clientHeight;
  let unit = (containerWidth * ratio) / blocks;
  let origin = defaultOrigin * unit;

  function _onResize() {
    containerWidth = container.clientWidth;
    containerHeight = container.clientHeight;

    canvas.style.width = `${containerWidth}px`
    canvas.style.height = `${containerHeight}px`
    canvas.width = containerWidth * ratio;
    canvas.height = containerHeight * ratio;

    unit = canvas.width / blocks;
    origin = defaultOrigin * unit;
  };

  function _runLoop(timestamp) {
    index += speed;

    getNewDrawPoints(simplex, initialPoints, index, offset)
      .then(drawPoints => {
        return drawCanvas(canvas, ctx, fillColor, ratio, initialPoints, drawPoints, origin, unit);
      }).then(() => {
        window.requestAnimationFrame(_runLoop)
      });
  };

  function _init() {
    _onResize();
    _runLoop();

    window.addEventListener("resize", _onResize, false);
  };
}

export default function canvasAnimation (selector, initialPoints, speed, offset, blocks, defaultOrigin, fillColor = "#6B5DA4") {
  const container = document.querySelector(selector);

  if (container) {
    run(container, initialPoints, speed, offset, blocks, defaultOrigin, fillColor);
  }
}
