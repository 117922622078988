import {createFunctionWithTimeout} from "./utils";

export function gaEnabled() {
  return "ga" in window && window.ga !== undefined &&
    typeof window.ga === "function";
}

function handleTrackedClick(e) {
  var el = e.currentTarget;
  var linkClicked = false;
  
  function handleClick() {
    if (!linkClicked) {
      linkClicked = true;
      document.location = el.getAttribute("href");
    }
  }
  
  if (gaEnabled()) {
    if (el.querySelectorAll(".js-track--link") !== []) {
      e.preventDefault();

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "Tracked Link Click",
        eventCategory: el.getAttribute("data-trackcategory"),
        eventAction: el.getAttribute("data-trackaction"),
        eventLabel: el.getAttribute("data-tracklabel"),
        eventValue: el.getAttribute("data-trackvalue"),
        eventCallback: function() { handleClick(); }
      });
    }
  }
  
}

export default function gaClickTracking() {
  var els = document.querySelectorAll(".js-track");

  [].forEach.call(els, el => {
    el.addEventListener("click", handleTrackedClick, false);
  });
}
