import ScrollMagic from 'ScrollMagic'
import '../animation.gsap';

import initializr from '../initializr';
import NavModal from '../modules/nav_modal';
import Modal from '../modules/modal';
import gaClickTracking from '../modules/tracking';
import attachment from '../modules/attachment';
import subscribe from '../modules/subscribe';
import videoReplace from "../modules/video_replace";

import {
  subtleMouseMove,
  footerArrowAnimation,
  aboutImageMaskAnimation,
  featureBlockBottomBlobAnimation,
  homeImageMaskAnimation,
  logoSwipeAnimation
} from '../modules/animations';

import canvasAnimation from '../modules/animations/canvasAnimation';
import { Point } from '../modules/animations/helpers';

initializr({
  common: {

    initialize: () => {
      gaClickTracking();
      videoReplace();
      new NavModal();

      const initialFooterPoints = [new Point(0, 0), new Point(6, 3), new Point(18, 1), new Point(26, 3.5), new Point(30, 0)];
      canvasAnimation("#footerCanvasContainer", initialFooterPoints, 0.00095, 2.5, 20, -8);

      const initialTopPoints = [new Point(0, 0), new Point(-2, 2), new Point(5, 7), new Point(9, 4), new Point(14, 5), new Point(16, 0)]
      canvasAnimation(".feature-blob--top", initialTopPoints, 0.001, 1.2, 20, 3, "#00cbea");

      const initialSidePoints = [new Point(0, 0), new Point(-3, 5), new Point(-2.5, 10), new Point(-3.5, 15), new Point(0, 19)]
      canvasAnimation(".side-blob--with-dot", initialSidePoints, 0.001, 1.2, 10, 10, "#433388");

      const initialBottomPoints = [new Point(0, 0), new Point(-3, 5), new Point(-2.5, 10), new Point(-3.5, 15), new Point(0, 19)]
      canvasAnimation(".feature-blob--bottom--with-dot", initialSidePoints, 0.001, 1.2, 10, 10, "#00CBEA");

      ['mousemove', 'touchstart', 'touchmove'].forEach(event => {
        if (window.innerWidth > 480) {
          window.addEventListener(event, (e) => {
            subtleMouseMove(e, '.js-mousemove')
          }, false);
        }
      })

      const overlay = document.querySelector(".js-overlay");
      if (overlay) {
        const button = overlay.querySelector(".js-overlay__button");
        button.addEventListener("click", (e) => {
          e.preventDefault();
          overlay.classList.add("is-closed");
        });
      }

      setTimeout(() => {
        if (overlay.classList.contains("footer-promo")) {
          overlay.classList.remove("is-closed");
        }
      }, 1000);
    }
  },
  pages: {
    initialize: () => {},
    index: () => {
      const controller = new ScrollMagic.Controller();
      footerArrowAnimation(controller);
      featureBlockBottomBlobAnimation(controller, '.feature-blob--bottom');
      homeImageMaskAnimation(controller);
      logoSwipeAnimation();
    },
    research: () => {
      subscribe();
    },
    subscribe: () => {
      subscribe();
    },
    about: () => {
      const controller = new ScrollMagic.Controller();
      footerArrowAnimation(controller);
      aboutImageMaskAnimation(controller);
    },
    contact: () => {}
  },
  case_studies: {
    initialize: () => {},
    index: () => {},
    show: () => {}
  },
  services: {
    initialize: () => {},
    index: () => {
      const controller = new ScrollMagic.Controller();
      footerArrowAnimation(controller);
      aboutImageMaskAnimation(controller);
    },
    show: () => {}
  },
  blogs: {
    initialize: () => {},
    index: () => {
      const controller = new ScrollMagic.Controller();
      footerArrowAnimation(controller);
      subscribe();
    },
    show: () => {
      attachment();
      subscribe();
    }
  },
  join: {
    initialize: () => { },
    index: () => { },
    'professional-development': () => {
      subscribe();
    }
  }
});
