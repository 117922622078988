export function Point(x, y) {
  this.x = x;
  this.y = y;
  this.clone = function () { return new Point(this.x, this.y) }
}

export function getNewDrawPoints(simplex, points, index, offset) {
  function generateNewPoint(point, i) {
    const newPoint = point.clone();
    const value2d = simplex.noise2D(index + newPoint.y, 0) * offset;

    if (i === points.length - 1) {
      newPoint.x += value2d / 4;
      return newPoint;
    }

    newPoint.y += value2d;
    newPoint.x += value2d * 2;
    return newPoint;
  }

  const drawPoints = points.map(generateNewPoint);
  
  return Promise.resolve(drawPoints);
}

export function drawCanvas(canvas, ctx, fillColor, ratio, initialPoints, drawPoints, origin, unit) {
  ctx.fillStyle = fillColor;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.lineWidth = 1 * ratio;

  const getX = p => origin + (p.x * unit);
  const getY = p => canvas.height - (p.y * unit);
  const getXControl = (p1, p2) => origin + (((p1.x + p2.x) / 2) * unit);
  const getYControl = (p1, p2) => canvas.height - (((p1.y + p2.y) / 2) * unit);

  ctx.beginPath();
  ctx.moveTo(getX(initialPoints[0]), getY(initialPoints[0]));

  for (let i = 1; i < drawPoints.length - 1; i++) {
    const pt1 = drawPoints[i];
    const pt2 = drawPoints[i + 1];

    ctx.quadraticCurveTo(
      getX(pt1), getY(pt1),
      getXControl(pt1, pt2), getYControl(pt1, pt2)
    );

    if (i === drawPoints.length - 2) {
      ctx.quadraticCurveTo(
        getXControl(pt1, pt2), getYControl(pt1, pt2),
        getX(pt2), getY(pt2)
      );
    }
  }

  ctx.closePath();
  ctx.fill();

  return Promise.resolve();
}
