import TweenLite from 'gsap/umd/TweenLite';
import TimelineLite from 'gsap/umd/TimelineLite';
import CSSPlugin from 'gsap/umd/CSSPlugin';
import { Back, Power1, Sine } from 'gsap/umd/EasePack';
import ScrollMagic from 'ScrollMagic';
import '../animation.gsap';
import '../MorphSVGPlugin';

// ---------------------------------------------------------
// mouse move
// ---------------------------------------------------------

export function subtleMouseMove(e, element) {
  const x = e.mouseMove ? e.mouseMove[0].clientX : e.clientX;
  const y = e.mouseMove ? e.mouseMove[0].clientY : e.clientY;
  const w = window.innerWidth / 2;
  const h = window.innerHeight / 2;

  const moveX = -(x - w) / (w / 10) - 10;
  const moveY = -(y - h) / (h / 10) - 10;

  TweenLite.to(element, 2, {
    x: moveX / 0.5,
    y: moveY / 0.5
  });
}

export function footerArrowAnimation(controller) {
  const scene = new ScrollMagic.Scene({ triggerElement: '.js-arrow--trigger' });

  const tween = TweenLite.from('.triangle--green', 4, {
    yPercent: -100,
    xPercent: -50,
    ease: Sine.easeInOut,
    rotation: 60,
    delay: 0.5
  });

  scene.addTo(controller).setTween(tween);
}

export function aboutImageMaskAnimation(controller) {
  if (window.innerWidth > 480) {
    const aboutImageAnimation = new TimelineLite();
    const aboutImageScene = new ScrollMagic.Scene({
      triggerHook: 0.25,
      triggerElement: '.js-trigger--purple-blob'
    });

    aboutImageAnimation
      .timeScale(0.5)
      .to(
        '.js-svg__about-image-blob .js-morph__image--start',
        8,
        {
          morphSVG: {
            shape: '.js-svg__about-image-blob .js-morph__image--end',
            shapeIndex: 10
          },
          ease: Back.easeOut.config(2)
        },
        0
      )
      .to(
        '.js-svg__about-image-blob .js-morph__blue--start',
        6,
        {
          morphSVG: {
            shape: '.js-svg__about-image-blob .js-morph__blue--end',
            shapeIndex: 4
          },
          ease: Back.easeInOut.config(2)
        },
        0
      )
      .to(
        '.js-svg__about-image-blob .js-morph__yellow--start',
        6,
        {
          morphSVG: {
            shape: '.js-svg__about-image-blob .js-morph__yellow--end',
            shapeIndex: 5
          },
          ease: Back.easeInOut.config(2)
        },
        0
      );

    aboutImageScene.setTween(aboutImageAnimation).addTo(controller);
  }
}

export function b2bImageMaskAnimation(controller) {
  if (window.innerWidth > 480) {
    const b2bImageAnimation = new TimelineLite();
    const b2bImageScene = new ScrollMagic.Scene({
      triggerHook: 0.25,
      triggerElement: '.js-trigger-blob-anim'
    });

    b2bImageAnimation
      .timeScale(0.4)
      .to(
        '.js-svg__b2b-image-blob .js-morph__image--start',
        8,
        {
          morphSVG: {
            shape: '.js-svg__b2b-image-blob .js-morph__image--end',
            shapeIndex: 10
          },
          ease: Back.easeOut.config(2)
        },
        0
      )
      .to(
        '.js-svg__b2b-image-blob .js-morph__orange--start',
        6,
        {
          morphSVG: {
            shape: '.js-svg__b2b-image-blob .js-morph__orange--end',
            shapeIndex: 5
          },
          ease: Back.easeInOut.config(2)
        },
        0
      );

    b2bImageScene.setTween(b2bImageAnimation).addTo(controller);
  }
}

export function featureBlockBottomBlobAnimation(controller, trigger) {
  const tl = new TimelineLite();

  tl.to(
    '#mainBottomBlobStart',
    10,
    {
      morphSVG: {
        shape: '#mainBottomBlobEnd',
        shapeIndex: 1
      },
      ease: Back.easeOut.config(2)
    },
    0
  )
    .to('#gooeycircle', 10, {
      xPercent: -320,
      yPercent: 100,
      ease: Power1.easeOut,
      delay: -8
    })
    .to('#gooeycircle', 0.5, {
      fill: '#5DD26D',
      delay: -6.8,
      ease: Power1.easeInOut
    });

  const scene = new ScrollMagic.Scene({
    triggerElement: trigger,
    offset: -200,
    reverse: false
  })
    .setTween(tl)
    .addTo(controller);
}

export function homeImageMaskAnimation(controller) {
  const tl = new TimelineLite();

  tl.timeScale(0.5).to(
    '.js-morph__image--start',
    8,
    {
      morphSVG: { shape: '.js-morph__image--end', shapeIndex: 10 },
      ease: Back.easeOut.config(2)
    },
    0
  );

  var scene = new ScrollMagic.Scene({ triggerElement: '.homeImageMaskTrigger' })
    .setTween(tl)
    .addTo(controller);
}

export function logoSwipeAnimation() {
  const logoStroke = document.querySelector('.logoStrokePlaceholder');
  TweenLite.set('.logoSolid', { autoAlpha: 0 });
  logoStroke.classList.add('animatelogoStroke');
}
