function handleTrackedAttachment(event) {
  const form = event.currentTarget;
  var formSubmitted = false;

  function handleAttachment() {
    if (!formSubmitted) {
      formSubmitted = true;
      const req = new XMLHttpRequest();
      req.open(form.method, form.action);
      req.addEventListener('readystatechange', () => {
        if (req.status >= 200 && req.status < 300) success();
        else failure();
      });
      req.send(new FormData(form));
    }
  }

  event.preventDefault();

  function success () {
    form.classList.add('is-hidden');
    document.querySelector('.js-attachment').querySelector('.js-success').classList.remove('is-hidden');
  }

  function failure () {
    document.querySelector('.js-attachment').querySelector('.js-failure').classList.remove('is-hidden');
  }

  handleAttachment();
}


export default function attachment () {
  const attachment = document.querySelector('.js-attachment');

  if (attachment) {
    attachment.querySelector('form').addEventListener('submit', handleTrackedAttachment, false);
  }
}
