import { hasClass, addClass, removeClass } from "./utils";
import EventEmitter from "events";

const mediator = new EventEmitter();

class Modal {
  constructor(selector = ".js-nav-toggle", name = "nav") {
    window.addEventListener("load", this._onLoad.bind(this), false);

    this.name = name;
    this.ACTIVE_CLASS = `${name}-open`;
    this.target = document.querySelector('html');
    this.trigger = document.querySelector(selector);
    this.lastWindowWidth = window.innerWidth;
  }

  toggle() {
    if (hasClass(this.target, this.ACTIVE_CLASS)) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    addClass(this.target, this.ACTIVE_CLASS);
    mediator.emit("open", this.name);
    mediator.on("open", this.onModalOpen.bind(this));
    window.addEventListener("resize", this._onResize.bind(this), false);
  }

  close(evt) {
    if (evt) {
      evt.preventDefault();
    }
    window.removeEventListener("resize", this._onResize);
    mediator.removeListener("open", this.onModalOpen);
    removeClass(this.target, this.ACTIVE_CLASS);
  }

  onModalOpen(name) {
    // Another modal might have been opened
    if (name !== this.name) {
      this.close();
    }
  }

  _onLoad() {
    this.trigger.addEventListener("click", this.toggle.bind(this), false);
    const close = document.querySelector(".js-modal-close");
    if (close) {
      close.addEventListener("click", this.close.bind(this), false);
    }
  }

  _onResize() {
    const open = hasClass(this.target, this.ACTIVE_CLASS);

    // Check if width has changed.
    // Mobile devices trigger resize event when the address bar goes away
    const xWidthChanged = this.lastWindowWidth !== window.innerWidth;

    if (open && xWidthChanged) {
      this.close();
    }
  }
}

Modal.NAV = "nav";
Modal.ENQUIRE = "enquire";

export default Modal
