import { closest } from './utils';

export default function videoReplace () {
  const videos = document.querySelectorAll(".js-video-replace");
  [].forEach.call(videos, (videoContainer) => {
    videoContainer.addEventListener('click', (event) => {
      event.preventDefault();
      event.stopPropagation();
      window.requestAnimationFrame(() => {
        const iframe = videoContainer.querySelector("iframe");
        const videoPlayer = new Vimeo.Player(iframe);
        videoPlayer.play();
        videoPlayer.enableTextTrack("en");
        videoContainer.classList.add("is-playing");
      });
    });
  });
}
