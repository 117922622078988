export function hasClass(el, className) {
  return el.classList
    ? el.classList.contains(className)
    : new RegExp("\\b" + className + "\\b").test(el.className);
}

export function addClass(el, className) {
  if (el.classList)
    el.classList.add(className);
  else if (!hasClass(el, className))
    el.className += " " + className;
}

export function removeClass(el, className) {
  if (el.classList)
    el.classList.remove(className);
  else
    el.className = el.className.replace(
      new RegExp("\\b" + className + "\\b", "g"),
      ""
    );
}

export function getPosition(el) {
  var xPosition = 0;
  var yPosition = 0;

  while (el) {
    if (el.tagName == "BODY") {
      // deal with browser quirks with body/window/document and page scroll
      var xScrollPos = el.scrollLeft || document.documentElement.scrollLeft;
      var yScrollPos = el.scrollTop || document.documentElement.scrollTop;

      xPosition += el.offsetLeft - xScrollPos + el.clientLeft;
      yPosition += el.offsetTop - yScrollPos + el.clientTop;
    } else {
      xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
      yPosition += el.offsetTop - el.scrollTop + el.clientTop;
    }

    el = el.offsetParent;
  }
  return { x: xPosition, y: yPosition };
}

export function closest (el, selector) {
  if (el.closest) return el.closest(selector);

  while (el) {
    if (matches(el, selector)) return el;
    el = el.parentElement;
  }

  return null;
}

export function matches (el, selector) {
  const method = el.matches || el.webkitMatchesSelector || el.oMatchesSelector || el.msMatchesSelector || el.mozMatchesSelector;
  return method.call(el, selector);
}

/*
 * Scroll to a vertial point on the page.
 * @param <Number> scrollTargetY: the target scrollY property of the window
 * @param <Number>  speed: time in pixels per second
 * @param <String> easing: equation to use
 * @param <function> callback, optional
 */
export const scrollToY = function(scrollTargetY, speed, easing, callback=null) {
  var scrollY = window.scrollY || document.documentElement.scrollTop,
    scrollTargetY = scrollTargetY || 0,
    speed = speed || 2000,
    easing = easing || "easeOutSine",
    currentTime = 0;

  // min time .1, max time .8 seconds
  var time = Math.max(
    0.1,
    Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8)
  );

  // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
  var easingEquations = {
    easeOutSine: function(pos) {
      return Math.sin(pos * (Math.PI / 2));
    },
    easeInOutSine: function(pos) {
      return -0.5 * (Math.cos(Math.PI * pos) - 1);
    },
    easeInOutQuint: function(pos) {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5);
      }
      return 0.5 * (Math.pow(pos - 2, 5) + 2);
    }
  };

  // add animation loop
  function tick() {
    currentTime += 1 / 60;

    var p = currentTime / time;
    var t = easingEquations[easing](p);

    if (p < 1) {
      requestAnimationFrame(tick);

      window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
    } else {
      if (callback) {
        callback();
      }
      window.scrollTo(0, scrollTargetY);
    }
  }

  // call it once to get started
  tick();
}

scrollToY.easeOutSine = 'easeOutSine';
scrollToY.easeInOutSine = 'easeInOutSine';
scrollToY.easeInOutQuint = 'easeInOutQuint';


export function createFunctionWithTimeout(callback, opt_timeout) {
  var called = false;
  function fn() {
    if (!called) {
      called = true;
      callback();
    }
  }
  setTimeout(fn, opt_timeout || 1);
  return fn;
}
