import { hasClass, addClass, removeClass } from "./utils";
import TweenLite from 'gsap/umd/TweenLite';
import TimelineLite from 'gsap/umd/TimelineLite';
import CSSPlugin from 'gsap/umd/CSSPlugin';
import { Back, Sine } from 'gsap/umd/EasePack';
import EventEmitter from "events";

const mediator = new EventEmitter();

class NavModal {
  constructor(selector = ".nav__button") {
    window.addEventListener("load", this._onLoad.bind(this), false);

    this.ACTIVE_CLASS = 'nav-open';
    this.target = document.querySelector('html');
    this.trigger = document.querySelector(selector);
    this.lastWindowWidth = window.innerWidth;

    this.navTl = new TimelineLite({ paused: true });
    this.hamburgerTl = new TimelineLite({ paused: true });
  }

  toggle() {
    if (hasClass(this.target, this.ACTIVE_CLASS)) {
      this.close();
    } else {
      this.open();
    }
  }

  open() {
    addClass(this.target, this.ACTIVE_CLASS);
    this.navTl.play().timeScale(1);
    this.hamburgerTl.play();

    window.addEventListener("resize", this._onResize.bind(this), false);
  }

  close(evt) {
    if (evt) { evt.preventDefault(); }
    window.removeEventListener("resize", this._onResize);
    removeClass(this.target, this.ACTIVE_CLASS);
    this.navTl.reverse().timeScale(2.3)
    this.hamburgerTl.reverse();
  }

  _onLoad() {
    this.trigger && this.trigger.addEventListener("click", this.toggle.bind(this), false);

    this.navTl
      .to(".morph-shape", 0.5, { xPercent: -120, }, 0)
      .to("#morphStart", 1, { morphSVG: "#morphEnd", ease: Sine.easeOut }, 0)
      .to(".nav--site", 0.3, { autoAlpha: 1, delay: -0.3 });

    this.hamburgerTl
      .to(".line2", 0.1, { opacity: 0 }, 0)
      .to(".line1", 0.3, { rotation: -45, y: 7, x: -0.5, transformOrigin: "50% 0%", ease: Back.easeOut.config(1.4) }, 0)
      .to(".line3", 0.3, { rotation: 45, y: -7, x: 0.5, transformOrigin: "50% 0%", ease: Back.easeOut.config(1.4) }, 0)
  }

  _onResize() {
    const open = hasClass(this.target, this.ACTIVE_CLASS);

    // Check if width has changed.
    // Mobile devices trigger resize event when the address bar goes away
    const xWidthChanged = this.lastWindowWidth !== window.innerWidth;

    if (open && xWidthChanged) {
      this.navTl.pause(0, true)
      this.hamburgerTl.pause(0, true)
      removeClass(this.target, this.ACTIVE_CLASS)
    }
  }
}

export default NavModal
